<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/consult/banner.jpg" alt="" />
    </div>

    <div class="container">
      <div class="box">
        <div class="one_title">服务内容</div>
        <div class="nr_list">
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/nr1.png" alt="" />
            </div>
            <div class="nr_title">税务咨询</div>
            <div class="nr_content">
              <span>提供有关税务法规、政策</span>
              <span>及实务操作的咨询和建议</span>
            </div>
          </div>
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/nr2.png" alt="" />
            </div>
            <div class="nr_title">税务风险评估</div>
            <div class="nr_content">
              <span>全面评估企业务风险</span>
              <span>并提出应对策略和建议</span>
            </div>
          </div>
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/nr1.png" alt="" />
            </div>
            <div class="nr_title">税务审计</div>
            <div class="nr_content">
              <span>进行内部审计和外部审</span>
              <span>计，确保企业税务合规</span>
            </div>
          </div>
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/nr1.png" alt="" />
            </div>
            <div class="nr_title">税务培训</div>
            <div class="nr_content">
              <span>为企业内部人员提供税务</span>
              <span>培训服务，提高员工的税</span>
              <span>务意识和操作能力</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="one_title">服务特色</div>
        <div class="nr_list">
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/ts1.png" alt="" />
            </div>
            <div class="ts_title">专业团队</div>
            <div class="ts_content">
              <span>资深税务专家团队，为您</span>
              <span>提供高质量的税务服务</span>
            </div>
          </div>
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/ts2.png" alt="" />
            </div>
            <div class="ts_title">全面服务</div>
            <div class="ts_content">
              <span>涵盖企业所得税、增值税</span>
              <span>个人所得税等各类税种</span>
            </div>
          </div>
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/ts3.png" alt="" />
            </div>
            <div class="ts_title">高效响应</div>
            <div class="ts_content">
              <span>及时响应客户需求，确</span>
              <span>保问题得到迅速解决</span>
            </div>
          </div>
          <div class="nr_item">
            <div class="nr_icon">
              <img src="../../assets/img/consult/ts4.png" alt="" />
            </div>
            <div class="ts_title">诚信保密</div>
            <div class="ts_content">
              <span>严格遵守保密义务</span>
              <span>确保客户信息安全</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn" @click="handleApply">立即咨询</div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "26";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #195bf8;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.container_title span {
  display: block;
  margin: 20px 0;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.container_bg .one_title {
  color: #fff;
}

.two_title {
  font-size: 18px;
  color: #949494;
  text-align: center;
  margin-top: 10px;
}

.nr_list {
  display: flex;
  margin-top: 80px;
}

.nr_item {
  width: 25%;
}

.nr_icon {
  width: 74px;
  height: 74px;
  margin: 0 auto;
}

.nr_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.nr_title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 600;
}

.nr_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #949494;
}

.nr_content span {
  display: block;
}

.ts_title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #fff;
  font-weight: bold;
}

.ts_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

.ts_content span {
  display: block;
}

.btn {
  width: 205px;
  height: 58px;
  background: #055afe;
  border-radius: 29px;
  color: #fff;
  line-height: 58px;
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
  font-size: 24px;
  font-weight: 600;
  color: #fffefe;
  cursor: pointer;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
